<template>
  <div class="division">
    <div
      class="simpleInfoPanel"
      v-if="hasPermission"
    >
      <el-tree
        ref="tree"
        node-key="id"
        class="filter-tree w-is-scroll fl"
        :default-expanded-keys="[0]"
        :data="treeData"
        :props="{
          label: 'name',
          children: 'managementVOS'
        }"
        @node-click="handleDivision"
      >
        <template slot-scope="{ data }">
          <span class="el-tree-node__label">
            <i :class="data.icon"></i>{{ data.name }}
          </span>
        </template>
      </el-tree>
      <div
        class="divisionContent"
        v-if="Object.keys(nodeData).length"
      >
        <h2>{{nodeData.name0 || '--'}}</h2>
        <h3 v-if="nodeData.type !== 3">{{nodeData.count || 0}}人</h3>
        <p v-if="nodeData.type === -1">姓名：{{this.user.userName||'--'}}</p>
        <p v-if="nodeData.type === -1">角色：{{this.user.roleName||'--'}}</p>
        <p v-if="nodeData.type === -1">
          操作：
          <span @click="showForm(0)">新增子部门</span>
        </p>
        <br>
        <p v-if="nodeData.type===3">用户名：{{nodeData.user || '--'}}</p>
        <p v-if="nodeData.type!==-1">
          {{'部门：'}}
          {{(nodeData.proto?nodeData.proto.join('/'):'--') || '--'}}
        </p>
        <p v-if="nodeData.type===3">角色：{{(nodeData.roleName || '--')}}</p>
        <p v-if="nodeData.type!==-1">
          操作：
          <template v-if="nodeData.type === 1 || nodeData.type === 2">
            <span
              v-if="nodeData.level !== 5"
              @click="showForm(0)"
            >新增子部门</span>
            <span @click="showForm(1)">添加成员</span>
            <span @click="showForm(2)">部门重命名</span>
            <span @click="deleteDivision(nodeData)">删除</span>
          </template>
          <span
            @click="showForm(3)"
            v-if="nodeData.type === 3"
          >移动部门</span>
          <!-- <span @click="deleteMember(nodeData.id, nodeData.name)" v-if="nodeData.type === 2">删除</span> -->
        </p>
      </div>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
    <el-dialog
      class="manager-dialog"
      :title="form.title"
      :visible.sync="dialogFormVisible"
      :label-width="formLabelWidth"
    >
      <el-form
        :model="form"
        ref="form"
      >
        <el-form-item
          label="部门名称:"
          v-if="formIndex===0"
          prop="divisionName"
        >
          <el-input
            placeholder="请输入部门名称"
            type="textarea"
            v-model="form.divisionName"
          ></el-input>
          <p>可填写多个批量新增，多个以英文“,”分隔</p>
        </el-form-item>
        <el-form-item
          label=""
          v-if="formIndex===1"
        >
          <!-- <el-input placeholder="输入人员姓名或用户名搜索添加" v-model="form.member"></el-input> -->
          <el-autocomplete
            class="inline-input"
            v-model="form.member"
            :fetch-suggestions="querySearch"
            placeholder="输入人员姓名或用户名搜索添加"
            :trigger-on-focus="false"
            @input="searchMember"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              {{ item.name }}
            </template>
          </el-autocomplete>
          <router-link to="/manageAccount/member"> 新增人员</router-link>
          <p>查找人员名称或登录用户名，若无结果，需先添加人员。</p>
        </el-form-item>
        <el-form-item
          v-if="formIndex===2"
          label=""
        >
          <el-input v-model="form.divisionName"></el-input>
        </el-form-item>
        <el-form-item
          v-if="formIndex===3"
          label=""
        >
          <el-cascader
            style="width:300px"
            v-model="form.divisionId"
            :options="divisionList"
            :props="{
              label: 'name0',
              children: 'division',
              checkStrictly: true,
              value: 'id'
            }"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button class="cancelbtn" @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          v-if="formIndex===1 "
          type="primary"
          :disabled="!form.isSelectMember"
          :title="!form.isSelectMember?'请先输入人员名字搜索选择；若无搜索结果请先新增人员':''"
          @click="formSwitch()"
        >确 定</el-button>
        <el-button
          v-else
          type="primary"
          @click="formSwitch()"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'division',
  components: {},
  data () {
    return {
      treeData: [{}],
      nodeData: {},
      dialogFormVisible: false,
      form: {
        title: '',
        parentId: '', // 新增子部门，移动部门
        project: '',
        divisionName: '',
        member: '', // 添加成员
        isSelectMember: false, // 添加成员 判断是否
        memberId: '', // 添加成员，移动部门
        divisionId: '' // 添加成员，部门重命名
      },
      formIndex: 0,
      projectOptions: [],
      formLabelWidth: '132px',
      memberList: [],
      userAuthorities: [],
      hasPermission: true,
      hasPermissionAdd: true,
      hasPermissionEdit: true,
      hasPermissionDel: true,
      divisionList: [],
      hasCheckAuthorities: 0 // 页面有时候获取不到user未执行权限判断，hasCheckAuthorities用来判断是否已经执行过权限判断，如果没有执行那么在watch.user()再执行一次
    }
  },
  mounted () {
    this.getDataProjList()
    this.checkAuthorities()
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    user (newValue) {
      if (newValue && !this.hasCheckAuthorities) {
        this.checkAuthorities()
      }
    }
  },
  methods: {
    checkAuthorities () {
      if (Object.keys(this.user).length) {
        this.userAuthorities = this.user.confIds.filter(item => {
          return item.id === 87
        })
        if (this.userAuthorities.length) {
          this.getDataDivisionInfo()
          this.hasPermission = Boolean(this.userAuthorities[0].func)
          this.hasPermissionAdd = this.userAuthorities[0].func.indexOf('add') !== -1
          this.hasPermissionEdit = this.userAuthorities[0].func.indexOf('edit') !== -1
          this.hasPermissionDel = this.userAuthorities[0].func.indexOf('del') !== -1
        } else {
          this.hasPermission = false
        }
        this.hasCheckAuthorities++
      }
    },
    tellMe (val) {
      console.log('val', val)
    },
    getDataDivisionInfo () {
      const params = {
      }
      this.axios.post(api.departmentList, params).then(res => { // （0集团,1项目,2部门,3用户）
        if (res.data.code === 0) {
          const resData = res.data.data
          this.treeData = []
          const sGroup = { // 接口返回问题，自己拼接一个集团对象
            name: this.user.company,
            name0: this.user.company,
            managementVOS: [],
            id: -1,
            type: -1,
            count: resData.count
          }
          this.treeData.push(sGroup)
          this.treeData[0].managementVOS = resData.managementVOS.map(item => {
            item.name0 = item.name
            item.proto = []
            item.proto.push(this.user.company, item.name)
            if (item.type === 1 || item.type === 2) { // 项目、总账号或未绑定部门用户
              item.icon = 'icon-division'
              if (item.count) {
                item.name = item.name + '(' + item.count + '人)'
              }
              if (item.managementVOS && item.managementVOS.length) {
                for (let i = 0; i < item.managementVOS.length; i++) { // 一级部门或者项目成员
                  const item1 = item.managementVOS[i]
                  item1.proto = []
                  item1.proto.push(...item.proto)
                  item1.name0 = item1.name
                  if (item1.type === 2) { // 一级部门
                    item1.proto.push(item1.name)
                    item1.icon = 'icon-division'
                    if (item1.count) {
                      item1.name = item1.name + ' (' + item1.count + '人)'
                    }
                    if (item1.managementVOS && item1.managementVOS.length) {
                      for (let j = 0; j < item1.managementVOS.length; j++) { // 二级部门
                        const item2 = item1.managementVOS[j]
                        item2.proto = []
                        item2.proto.push(...item1.proto)
                        item2.name0 = item2.name
                        if (item2.type === 2) { // 二级部门
                          item2.icon = 'icon-division'
                          item2.proto.push(item2.name0)
                          if (item2.count) {
                            item2.name = item2.name + ' (' + item2.count + '人)'
                          }
                          if (item2.managementVOS && item2.managementVOS.length) {
                            for (let k = 0; k < item2.managementVOS.length; k++) {
                              const item3 = item2.managementVOS[k]
                              item3.icon = 'icon-member'
                              item3.proto = []
                              item3.name0 = item3.name
                              item3.proto.push(...item2.proto)
                              if (item3.type === 2) { // 三级部门
                                item3.icon = 'icon-division'
                                item3.proto.push(item3.name0)
                                if (item3.count) {
                                  item3.name = item3.name + ' (' + item3.count + '人)'
                                }
                                if (item3.managementVOS && item3.managementVOS.length) {
                                  for (let l = 0; l < item3.managementVOS.length; l++) {
                                    const item4 = item3.managementVOS[l]
                                    item4.icon = 'icon-member'
                                    item4.proto = []
                                    item4.name0 = item4.name
                                    item4.proto.push(...item3.proto)
                                    if (item4.type === 2) { // 三级部门
                                      item4.icon = 'icon-division'
                                      item4.proto.push(item4.name0)
                                      if (item4.count) {
                                        item4.name = item4.name + ' (' + item4.count + '人)'
                                      }
                                      if (item4.managementVOS && item4.managementVOS.length) {
                                        for (let l = 0; l < item4.managementVOS.length; l++) {
                                          const item5 = item4.managementVOS[l]
                                          item5.icon = 'icon-member'
                                          item5.proto = []
                                          item5.level = 5
                                          item5.name0 = item5.name
                                          item5.proto.push(...item4.proto)
                                          if (item5.type === 2) { // 三级部门
                                            item5.icon = 'icon-division'
                                            item5.proto.push(item5.name0)
                                            if (item5.count) {
                                              item5.name = item5.name + ' (' + item5.count + '人)'
                                            }
                                          } else { // 成员
                                            item5.index = 1
                                            item5.icon = 'icon-member'
                                            item5.projectId = item.projectId
                                          }
                                        }
                                      }
                                    } else { // 成员
                                      item4.index = 1
                                      item4.icon = 'icon-member'
                                      item4.projectId = item.projectId
                                    }
                                  }
                                }
                              } else { // 成员
                                item3.index = 1
                                item3.icon = 'icon-member'
                                item3.projectId = item.projectId
                              }
                            }
                          }
                        } else { // 成员
                          item2.index = 1
                          item2.icon = 'icon-member'
                          item2.projectId = item.projectId
                        }
                      }
                    }
                  } else {
                    if (item1.name === this.user.userName) {
                      item1.name0 = this.user.company
                    } else {
                      item1.name0 = item1.name
                    }
                    item1.icon = 'icon-member'
                    item1.index = 1
                    item1.projectId = item.projectId
                  }
                }
              }
            } else {
              item.icon = 'icon-member'
              if (item.name === this.user.userName) {
                item.count = resData.count
                item.name0 = this.user.company
                item.type = -1
              }
            }
            return item
          })
          // this.treeData[0].managementVOS.unshift({
          //   name: this.user.userName,
          //   name0: this.user.company,
          //   id: 1,
          //   type: 2,
          //   icon: 'icon-member',
          //   count: this.treeData[0].count,
          //   proto: [this.user.company],
          //   roleName: this.user.roleName
          // })
          if (this.treeData[0].count) {
            this.treeData[0].name += '(' + this.treeData[0].count + '人)'
          }
          this.divisionList = resData.managementVOS.filter(item => {
            if (item.managementVOS && item.managementVOS.length) {
              item.division = item.managementVOS.filter(item1 => {
                if (item1.managementVOS && item1.managementVOS.length) {
                  item1.division = item1.managementVOS.filter(item2 => {
                    if (item2.managementVOS && item2.managementVOS.length) {
                      item2.division = item2.managementVOS.filter(item3 => {
                        if (item3.managementVOS && item3.managementVOS.length) {
                          item3.division = item3.managementVOS.filter(item4 => {
                            return item4.type === 2
                          })
                          if (!item3.division.length) {
                            delete item3.division
                          }
                        }
                        return item3.type === 2
                      })
                      if (!item2.division.length) {
                        delete item2.division
                      }
                    }
                    return item2.type === 2
                  })
                  if (!item1.division.length) {
                    delete item1.division
                  }
                }
                return item1.type === 2
              })
            }
            if (item.division && item.division.length === 0) {
              delete item.division
            }
            return item.type === 2 || item.type === 1
          })
          this.$nextTick(() => {
            document.querySelector('.el-tree-node__content').click()
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    getDataProjList () {
      this.axios.post(api.getUserProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    showForm (key) {
      this.formIndex = key
      const titleArr = ['新增部门', '添加成员到部门', '部门重命名', '移动部门']
      this.form.title = titleArr[key]
      switch (key) {
        case 0:
          // 如果是项目，那么传projectId,parentId置空
          // 如果是一级部门，那么传parentId
          if (!this.hasPermissionAdd) {
            this.hasnotPermission()
            return false
          }
          if (this.nodeData.type !== -1) {
            this.form.parentId = this.nodeData.id
          } else {
            this.form.parentId = ''
          }
          this.form.project = this.nodeData.projectId
          break
        case 1:
          if (!this.hasPermissionAdd) {
            this.hasnotPermission()
            return false
          }
          this.form.divisionId = this.nodeData.id
          this.form.isSelectMember = false
          break
        case 2:
          if (!this.hasPermissionEdit) {
            this.hasnotPermission()
            return false
          }
          this.form.divisionName = this.nodeData.name0
          this.form.divisionId = this.nodeData.id
          break
        case 3:
          if (!this.hasPermissionEdit) {
            this.hasnotPermission()
            return false
          }
          this.form.memberId = this.nodeData.id
          this.form.divisionId = ''
          break
        default:
          break
      }
      this.dialogFormVisible = true
    },
    formSwitch () {
      this.$refs.form.validate((valid) => {
        switch (this.formIndex) {
          case 0:
            this.createDivision()
            break
          case 1:
            this.setMemberToDivision()
            break
          case 2:
            this.renameDivision()
            break
          case 3:
            this.moveMemberToDivision()
            break
        }
      })
    },
    moveMemberToDivision () {
      if (!this.form.divisionId) {
        this.$message({ message: '请选择要移动的部门' })
        return false
      } else {
      }
      const params = {
        userid: this.form.memberId
      }
      console.log('this.form.divisionId', this.form.divisionId)
      if (this.form.divisionId.length > 1) {
        params.deploymentId = [...this.form.divisionId].pop()
      } else {
        params.deploymentId = [...this.form.divisionId].shift()
      }
      this.axios.post(api.updateMobileMember, params).then(res => {
        if (res.data.code === 0) {
          this.handleSuccess(res.data)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    renameDivision () {
      if (!this.form.divisionName) {
        this.$message({ message: '请输入部门名称' })
        return false
      }
      const params = {
        id: this.form.divisionId,
        name: this.form.divisionName
      }
      this.axios.post(api.updateDeploymentName, params).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getDataDivisionInfo()
          this.resetForm()
          this.dialogFormVisible = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleSuccess (resData) {
      if (resData.code === 0) {
        this.$message({
          type: 'success',
          message: resData.msg
        })
        this.getDataDivisionInfo()
        this.resetForm()
        this.dialogFormVisible = false
      } else {
        this.$message({
          type: 'error',
          message: resData.data.msg
        })
      }
    },
    setMemberToDivision () {
      const params = {
        deploymentId: this.form.divisionId,
        userid: this.form.memberId
      }
      this.axios.post(api.addDeploymentUsers, params).then(res => {
        if (res.data.code === 0) {
          this.handleSuccess(res.data)
        } else {
          this.resetForm()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    createDivision () {
      if (!this.form.divisionName) {
        this.$message({ message: '请填写部门名称' })
        return false
      }
      const params = {
        name: this.form.divisionName.split(','),
        parentId: this.form.parentId
      }
      this.axios.post(api.addDeployment, params).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            this.getDataDivisionInfo()
            this.resetForm()
            this.dialogFormVisible = false
          } else if (res.data.data.code === 1) {
            this.$message({
              type: 'error',
              message: res.data.data.msg
            })
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    resetForm () {
      this.form = {
        key: '',
        title: '',
        parentId: '',
        project: '',
        divisionName: '',
        member: ''
      }
    },
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    querySearch (queryString, cb) {
      const memberList = this.memberList
      const results = memberList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelect (item) {
      this.form.member = item.name
      this.form.memberId = item.id
      this.form.isSelectMember = true
    },
    searchMember (key) {
      if (key) {
        this.form.isSelectMember = false
        const params = {
          keyword: key
        }
        this.axios.post(api.getUserBykeyword, params).then(res => {
          if (res.data.code === 0) {
            const resData = res.data.data
            this.memberList = resData
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    deleteDivision (data) {
      if (!this.hasPermissionDel) {
        this.hasnotPermission()
        return false
      }
      const params = {
        id: data.id
      }
      if (data.managementVOS === null || data.managementVOS.length === 0) {
        this.$confirm('是否删除部门 ' + data.name, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.axios.post(api.deleteStructure, params).then(res => {
            if (res.data.code === 0) {
              this.handleSuccess(res.data)
            }
          }, rej => {
            console.log(rej)
          }).catch(err => {
            console.log(err)
          })
        }).catch(() => {
        })
      } else {
        this.$message({
          type: 'info',
          message: '请先将部门成员转移或删除再进行删除部门操作'
        })
      }
    },
    deleteMember (id, member) {
      const params = {
        userId: id
      }
      this.$confirm('是否删除成员 ' + member, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post(api.deleteDeployment, params).then(res => {
          if (res.data.code === 0) {
            this.handleSuccess(res.data)
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
      })
    },
    getDataDivision (type, id) {
      const params = {
      }
      this.axios.post(api.departmentManagement, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.treeData = [ // 添加最高一级集团或公司
            {
              name: this.user.company,
              name0: this.user.company,
              managementVOS: [],
              id: 0,
              type: -1,
              count: 0
            }
          ]
          this.treeData[0].managementVOS = resData.map(item => { // 二级：部门，一级从用户账户中添加进去，二级只会存在总账号一个成员。
            this.treeData[0].count += item.count
            item.name0 = item.name
            if (item.managementVOS && item.managementVOS.length) {
              item.proto = []
              item.icon = 'icon-division'
              item.proto.push(this.user.company, item.name)
              item.name = item.name + '(' + item.count + '人)'
              for (let i = 0; i < item.managementVOS.length; i++) { // 三级：子部门
                const item1 = item.managementVOS[i]
                item1.proto = []
                item1.proto.push(...item.proto)
                item1.name0 = item1.name
                if (item1.type !== 2) { // 三级部门
                  item1.proto.push(item1.name)
                  item1.icon = 'icon-division'
                  item1.name = item1.name + ' (' + item1.count + '人)'
                  if (item1.managementVOS && item1.managementVOS.length) {
                    for (let j = 0; j < item1.managementVOS.length; j++) { // 四级
                      const item2 = item1.managementVOS[j]
                      item2.proto = []
                      item2.proto.push(...item1.proto)
                      item2.name0 = item2.name
                      if (item2.type !== 2) { // 四级部门，部门只有两级，下一级只能是成员
                        item2.icon = 'icon-division'
                        item2.proto.push(item2.name0)
                        item2.name = item2.name + ' (' + item2.count + '人)'
                        if (item2.managementVOS && item2.managementVOS.length) {
                          for (let k = 0; k < item2.managementVOS.length; k++) {
                            const item3 = item2.managementVOS[k]
                            item3.icon = 'icon-member'
                            item3.proto = []
                            item3.name0 = item3.name
                            item3.proto.push(...item2.proto)
                          }
                        }
                      } else { // 四级成员
                        item2.index = 1
                        item2.icon = 'icon-member'
                        item2.projectId = item.projectId
                      }
                    }
                  }
                } else {
                  if (item1.name === this.user.userName) {
                    item1.name0 = this.user.company
                  } else {
                    item1.name0 = item1.name
                  }
                  item1.icon = 'icon-member'
                  item1.index = 1
                  item1.projectId = item.projectId
                }
              }
            }
            return item
          })
          this.treeData[0].managementVOS.unshift({
            name: this.user.userName,
            name0: this.user.company,
            id: 1,
            type: 2,
            icon: 'icon-member',
            count: this.treeData[0].count,
            proto: [this.user.company],
            roleName: this.user.roleName
          })
          this.treeData[0].name += '(' + this.treeData[0].count + '人)'
          this.divisionList = resData.map(item => {
            if (item.managementVOS && item.managementVOS.length) {
              item.division = item.managementVOS.filter(item1 => {
                if (item1.managementVOS && item1.managementVOS.length) {
                  item1.division = item1.managementVOS.filter(item2 => {
                    return item2.type === 1
                  })
                  if (!item1.division.length) {
                    delete item1.division
                  }
                }
                return item1.type === 1
              })
            }
            return item
          })
          this.$nextTick(() => {
            document.querySelector('.el-tree-node__content').click()
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    loadNode (node, resolve) {
      const self = this
      if (node.level === 0) {
        self.axios.post(api.getUserDepartmentListCtr, {
        }).then(res => {
          if (res.data.code === 0) {
            resolve(res.data.data.departmentVOS)
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      } else if (node.level >= 1) {
        self.axios.post(api.getUserDepartmentListCtr, {
          id: node.data.id,
          type: node.data.type
        }).then(res => {
          if (res.data.code === 0 && res.data.data.departmentVOS) {
            resolve(res.data.data.departmentVOS)
          } else {
            resolve([])
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    handleDivision (val) {
      this.nodeData = val
    }
  }
}
</script>

<style scoped lang="stylus">
.division>>>
  .w-is-scroll
    padding-bottom 20px !important
.division
  height 648px
  overflow hidden
  .simpleInfoPanel
    height 100%
    padding 0
    .el-tree
      width 20%
      border-right 1px solid rgba(255, 255, 255, 0.1)
      height 100%
      overflow-y auto
    .divisionContent
      box-sizing border-box
      margin 35px 0 0 23%
      width 500px
      min-height 207px
      padding 20px 30px 0 30px
      box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
      font-size 14px
      h2, h3
        font-size 20px
        line-height 28px
        text-indent 32px
      h2
        background url('~@/assets/images/managerCenter/division-project.png') no-repeat left
        background-size 22px 22px
      p
        line-height 28px
        span
          display inline-block
          margin 0 5px
          color #FFA134
          text-decoration underline
          cursor pointer
.division>>>
  .el-autocomplete
    width 300px
  .el-tree
    padding 10px 0 0 10px
    .el-tree-node__content
      height 29px !important
      .el-tree-node__label
        font-size 15px !important
</style>
